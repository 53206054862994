import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import tw, { styled } from "twin.macro"

import Layout from "../components/layout"
import SEO from "../components/seo"

const StyledOl = styled.ol`
  list-style: none;
`

// responsive styles for articles are here
const StyledArticle = styled.article`
  ${tw`flex flex-col-reverse lg:flex-row sm:mx-16 md:mx-40 py-8`}
  ${({ notFirst }) => notFirst && `border-top: 1px solid rgba(0, 0, 0, 0.1);`}
`

const StyledImageContainer = styled.div`
  ${tw`w-full lg:w-3/5 text-center`}
`

const StyledTextContainer = styled.div`
  ${tw`w-full xs:px-4 lg:w-2/5 mr-4`}
`

const StyledH3 = styled.h3`
  ${tw`mb-1 font-bold xs:text-xl md:text-2xl lg:text-3xl`}
`

const StyledLink = styled(Link)`
  ${tw`hover:text-blue-700`}
  box-shadow: none;
  text-decoration: none !important;
`

const StyledUl = styled.ul`
  ${tw`flex justify-between items-center mt-5 sm:mx-16 md:mx-40`}
`

const StyledSpan = styled.span`
  ${tw`text-white bg-blue-700 p-2`}
`

const BlogIndex = ({ data, pageContext }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allContentfulPost.edges

  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout title={siteTitle}>
      <SEO title="All posts" />
      <StyledOl>
        {posts.map(({ node }, index) => {
          const title = node.title || node.slug
          return (
            <li key={node.slug}>
              <StyledArticle notFirst={index === 0 ? false : true}>

                <StyledTextContainer>
                  <header>
                    <StyledH3>
                      <StyledLink to={`/${node.slug}`}>
                        {title}
                      </StyledLink>
                    </StyledH3>
                    <small>{node.date}</small>
                  </header>
                  <section>
                    <p>{node.subtitle}</p>
                  </section>
                </StyledTextContainer>

                {node.image &&
                  <StyledImageContainer>
                    <Img
                      fluid={node.image.fluid}
                      alt={node.image.description || node.image.title || ''}
                      style={{
                        maxHeight: '100%',
                        height: '100%',
                        overflow: 'visible'
                      }}
                      imgStyle={{
                        objectFit: 'contain',
                      }}
                    />
                  </StyledImageContainer>
                }
              </StyledArticle>
            </li>
          )
        })}
      </StyledOl>
      
      {/* navigation */}
      <StyledUl>
        {!isFirst && (
          <StyledLink to={prevPage === "/" ? "/" : `/${prevPage}`} rel="prev">
            ← Previous Page
          </StyledLink>
        )}

        {Array.from({ length: numPages }, (_, i) => (
          <li
            key={`pagination-number${i + 1}`}
            style={{
              margin: 0,
            }}
          >
            {i + 1 === currentPage ?
            <StyledSpan>
              {i + 1}
            </StyledSpan>
            :
            <StyledLink to={`/${i === 0 ? '' : i + 1}`}>
              {i + 1}
            </StyledLink>
            }
          </li>
        ))}

        {!isLast && (
          <StyledLink to={`/${nextPage}`} rel="next">
            Next Page →
          </StyledLink>
        )}
      </StyledUl>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query pageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPost(
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          subtitle
          image {
            fluid(maxWidth: 1088, maxHeight: 600) {
              ...GatsbyContentfulFluid
            }
            title
            description
          }
          author
          date
          slug
        }
      }
    }
  }
`
